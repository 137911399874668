<template>
  <el-row>
    <el-col class="mt-1" :sm="24" :md="12" :xl="12">
      <!-- 首页头部 -->
      <!-- <RadarChart /> -->
      <PieChart />
    </el-col>
    <el-col class="mt-1" :sm="24" :md="12" :xl="12">
      <!-- <el-col> -->
      <!-- 公告 -->
      <TimeLine />
    </el-col>
  </el-row>
  <el-row>
    <el-col class="mt-1 el-col-xl-24 el-col-xl-24" :sm="24" :md="24" :xl="24">
      <!-- 解析部分 -->
      <LineChart />
    </el-col>
    <!-- <el-col class="mt-1" :sm="24" :md="12" :xl="12"> -->
    <!-- 套餐信息 -->
    <!-- <PieChart /> -->
    <!-- </el-col> -->
  </el-row>

  <!-- 方块图标 -->
  <!-- <TopPanel /> -->
</template>

<script setup>
// 导入组件
import RadarChart from "./dashboard/RadarChart.vue";
import TimeLine from "./dashboard/TimeLine.vue";
import LineChart from "./dashboard/LineChart.vue";
import PieChart from "./dashboard/PieChart.vue";
import { useMessage } from "@/hooks/useMessage";
import { http } from "@/utils/http";
import { useStore } from "vuex";
import { onMounted } from "vue";
const store = useStore();
// onMounted(() => {
//   let userToken = localStorage.getItem("userToken");
//   if (userToken == null || userToken == "") {
//     window.location = "/login";
//   }
//   http
//     .get("/v1/user/getAuth", {
//       headers: {
//         userToken: localStorage.getItem("userToken"),
//       },
//     })
//     .then((response) => {
//       const res = response.data;
//       if (res.code !== 200) {
//         useMessage("error", res.message);
//         return;
//       }
//       store.commit("addname", res.data.username);
//       store.commit("addUserInfo", res.data.userInfo);
//       store.commit("addNotice", res.data.notice);
//       store.commit("addEmail", res.data.email);
//     })
//     .catch((error) => {
//       // closeLoading()
//       useMessage("error", "请求超时,请重新尝试解析");
//       // 在这里处理错误，例如显示一个友好的提示给用户
//       console.error("发生错误:", error);
//       // 或者将错误状态存储到Vue组件中的数据中，并在页面上显示友好的提示
//     });
// });
</script>
