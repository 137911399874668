<script setup>
import Banner from "../banner/index.vue";
import menuService from "@/hooks/useMenu";
import MenuItem from "./MenuItem.vue";
import { ElMenu } from "element-plus";
import { useRoute } from 'vue-router'
import { ref, watchEffect, computed } from 'vue'

const route = useRoute();
const acticeIndex = ref("");

watchEffect(() => {
  acticeIndex.value = menuService.getCurrentMenu(route)
});

const isCollapse = computed(() => {
  return menuService.close.value;
});
</script>

<template>
  <el-aside class="admin-menu border-base bg-dark" :class="{ close: menuService.close.value }">
    <div class="flex justify-center">
      <Banner />
    </div>
    <el-menu :collapse="isCollapse" :default-active="acticeIndex">
      <menu-item :sub-menu="menuService.menus.value" />
    </el-menu>
    <teleport to="body">
      <div
        v-show="!menuService.close.value"
        class="bg block md:hidden"
        @click="menuService.toggleState"
      ></div>
    </teleport>
  </el-aside>
</template>

<style lang="scss" scoped>
.el-aside {
  color: var(--el-text-color-primary);
}
.el-menu {
  border-right: none;
}
.admin-menu {
  @apply w-[250px] duration-500 dark:bg-black min-h-screen h-full border-r border-solid;
  &.close {
    @apply w-[70px] duration-500;
  }
}

@media screen and (max-width: 766px) {
  .admin-menu {
    @apply w-[250px] duration-500 absolute top-0 left-0 min-h-screen h-full border-r border-solid;
    &.close {
      @apply left-[-250px] duration-500;
    }
  }
  .bg {
    @apply bg-gray-600 w-screen h-screen z-20 opacity-75  absolute left-0 top-0;
  }
}
</style>
